import Image from 'next/image';
import { styled } from '@mui/material';
import palette from 'theme/palette';

export const desktopMoreMenuStyles = {
  imageCircleButton: {
    height: 48,
    width: 48,
    padding: 2,
    borderRadius: '50%',
    backgroundColor: palette.common.white,
    border: 'solid',
    borderWidth: 2,
    borderColor: palette.common.white,
    p: 0,
  },
  buttonActive: {
    borderColor: palette.primary.main,
  },
  placeholderBackground: {
    backgroundColor: palette.secondary.main,
  },
  imageContainer: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: palette.info.main,
  },

  menuContainer: {
    width: 240,
  },
};

export const DesktopMoreMenuImagePlaceholder = styled(Image)({
  width: '50%',
});
