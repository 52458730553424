import {
  getFromLocalStorage,
  localStorageKeys,
  setToLocalStorage,
} from '../localStorage';

export const getClientLanguage = () => {
  const savedLang = getFromLocalStorage(localStorageKeys.lang);
  return savedLang;
};

export const setClientLanguage = (lang) => {
  setToLocalStorage(localStorageKeys.lang, lang);
};
