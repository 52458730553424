import { swapObject } from '@nploy/shared';

export const fromWorkModelToSearchParams = {
  1: 'ons',
  2: 'hyb',
  3: 'hme',
  4: 'Remote',
} as const;

export const fromWorkModelSearchParamsToIds = swapObject(
  fromWorkModelToSearchParams,
);
