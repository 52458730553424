import palette from './palette';

const customScrollbar = {
  overflowY: 'auto',
  // firefox
  scrollbarWidth: 'thin',
  scrollbarColor: palette.success.main,
  // chrome
  /* width */
  '&::-webkit-scrollbar': {
    width: 8,
  },
  /* Track */
  '&::-webkit-scrollbar-track': {
    backgroundColor: palette.common.white,
  },
  /* Handle */
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.success.main,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: palette.common.white,
    borderRadius: 8,
  },
};

export default customScrollbar;
