import { FC } from 'react';
import Link from 'next/link';
import { Box } from '@mui/material';
import { dropdownNavLinks } from 'constants/dropdownNavLinks';
import useTranslate from 'utils/i18n/useTranslate';
import { DropdownNavLinks } from '../dropdown-nav-links';
import { dropdownNavLinksStyles } from '../dropdown-nav-links/dropdown-nav-links.styles';
import { INavLinks } from './nav-links.interface';
import { navLinksStyles } from './nav-links.styles';

export const NavLinks: FC<INavLinks> = ({ inDrawer = false }) => {
  const { t } = useTranslate();

  const filteredNavLinks = Object.keys(dropdownNavLinks)
    .filter((key) => key !== 'popularJobCategories' && key !== 'quickLinks')
    .reduce((obj, key) => {
      const newObj = { ...obj };
      newObj[key] = dropdownNavLinks[key];
      return newObj;
    }, {});

  return (
    <Box
      sx={{
        ...navLinksStyles.navLinks,
        ...(inDrawer && navLinksStyles.inDrawer),
      }}
      component="nav"
    >
      <Link
        href="/jobs"
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
      >
        <Box sx={dropdownNavLinksStyles.navDropdown(inDrawer)}>
          {t('navigation.findJobs')}
        </Box>
      </Link>
      {Object.keys(filteredNavLinks).map((dropdownTitle) => (
        <DropdownNavLinks
          key={dropdownTitle}
          dropdownTitle={t(filteredNavLinks[dropdownTitle].title)}
          links={filteredNavLinks[dropdownTitle].links}
          inDrawer={inDrawer}
        />
      ))}
    </Box>
  );
};
