import React, { FC } from 'react';
import Link from 'next/link';
import { useMediaQuery } from '@mui/material';
import logo from 'public/images/logo.svg';
import logoRound from 'public/images/logoRound.svg';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { LinkedLogoImage } from './linked-logo.styles';

export const LinkedLogo: FC = () => {
  const { t } = useTranslate();
  const isTablet = useMediaQuery(theme.breakpoints.down(1200));

  return (
    <Link
      href={{
        pathname: '/',
        query: {
          homePage: true,
        },
      }}
    >
      <LinkedLogoImage
        src={isTablet ? logoRound : logo}
        alt={t('navigation.nployLogo')}
        title="nPloy"
        priority
      />
    </Link>
  );
};
