import Image from 'next/image';
import { SxProps, Theme } from '@mui/material';
import { styled } from '@mui/styles';
import theme from 'theme/theme';

export const desktopLanguageMenuStyles: Record<string, SxProps<Theme>> = {
  imageCircleButton: {
    height: 35,
    width: 35,
    borderRadius: '50%',
    backgroundColor: theme.palette.common.white,
    border: 'solid',
    borderWidth: 2,
    borderColor: theme.palette.common.white,
    position: 'relative',
  },
  buttonActive: {
    borderColor: theme.palette.primary.main,
  },
  placeholderBackground: {
    backgroundColor: theme.palette.common.white,
  },
  imageContainer: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuContainer: {
    width: 240,
  },
};
export const DesktopLanguageMenuImage = styled(Image)({
  height: '100%',
});
