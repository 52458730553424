import { NextRouter } from 'next/router';

export const removeQueryParams = async (
  router: NextRouter,
  paramsToRemove: string[],
) => {
  const { pathname, query } = router;
  if (paramsToRemove.length) {
    paramsToRemove.forEach((param) => delete query[param]);
  }

  return router.replace({ pathname, query }, undefined, {
    shallow: true,
  });
};
