export const queryJobCategories = {
  'accounting-finance-and-statistics': 1,
  'administration-and-secretarial': 3,
  'architecture-and-construction': 4,
  'business-management-and-consulting': 5,
  'catering-hospitality-and-tourism': 6,
  'charity-and-voluntary-work': 7,
  'client-and-customer-service': 8,
  'compliance-risk': 27,
  'design-and-creative-arts': 9,
  'education-curriculum-and-teaching': 10,
  'engineers-and-technical-occupations': 11,
  'healthcare-and-therapy': 20,
  'hr-and-recruitment': 13,
  insurance: 14,
  'it-and-technology': 15,
  'legal-and-counselling': 16,
  'logistics-and-transportation': 17,
  'marketing-and-communications-and-pr': 18,
  'media-journalism-and-publishing': 19,
  'music-entertainment-and-event-management': 21,
  'production-and-manufacturing': 22,
  'property-and-real-estate': 23,
  'sales-and-trade': 24,
  'science-and-research': 25,
  security: 26,
};
