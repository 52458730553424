import { IFiltersValuesDto } from '@nploy/ui-infrastructure';

export const filtersValuesSearchParamsClassifier: Record<
  keyof IFiltersValuesDto,
  string
> = {
  cities: 'city',
  country_id: 'country',
  employment_levels: 'wt',
  employment_types: 'jt',
  job_occupations: 'category',
  keywords: 'kw',
  salary_max: 'salarymax',
  salary_min: 'salarymin',
  work_models: 'wm',
  experience_years_max: 'explvl',
  experience_years_min: 'explvl',
} as const;

export const filtersSearchParamsClassifierToValues: Record<string, string> = {
  city: 'cities',
  country: 'country_id',
  wm: 'work_models',
  jt: 'employment_types',
  wt: 'employment_levels',
  category: 'job_occupations',
  kw: 'keywords',
  salarymax: 'salary_max',
  salarymin: 'salary_min',
  explvl: 'experience_years_max',
};
