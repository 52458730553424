import { swapObject } from '@nploy/shared';

export const jobsCategoriseSearchParams = {
  1: 'accounting-finance-and-statistics',
  3: 'administration-and-secretarial',
  4: 'architecture-and-construction',
  5: 'business-management-and-consulting',
  6: 'catering-hospitality-and-tourism',
  7: 'charity-and-voluntary-work',
  8: 'client-and-customer-service',
  9: 'design-and-creative-arts',
  10: 'education-curriculum-and-teaching',
  11: 'engineers-and-technical-occupations',
  13: 'hr-and-recruitment',
  14: 'insurance',
  15: 'it-and-technology',
  16: 'legal-and-counselling',
  17: 'logistics-and-transportation',
  18: 'marketing-and-communications-and-pr',
  19: 'media-journalism-and-publishing',
  20: 'healthcare-and-therapy',
  21: 'music-entertainment-and-event-management',
  22: 'production-and-manufacturing',
  23: 'property-and-real-estate',
  24: 'sales-and-trade',
  25: 'science-and-research',
  26: 'security',
  27: 'compliance-risk',
} as const;

export const jobsCategoriseSearchParamsToValues = swapObject(
  jobsCategoriseSearchParams,
);
