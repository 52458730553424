import React from 'react';
import NextScript from 'next/script';
import {
  attributesToProps,
  DOMNode,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser';

const replaceScriptToNextScript = (node: Element) => {
  const { attribs } = node;

  const props = attributesToProps(attribs, 'script');
  return <NextScript {...props} />;
};

export const replaceHead = (node: DOMNode) => {
  if (node instanceof Element && node.attribs) {
    if (node.name === 'img') {
      return <></>;
    }
    if (node.name === 'link') {
      const props = attributesToProps(node.attribs, 'script');

      if (props?.rel === 'stylesheet') {
        return <></>;
      }
    }
    if (node.name === 'noscript') {
      return <></>;
    }
    if (node.name === 'script') {
      return replaceScriptToNextScript(node);
    }
  }
};

export const headParseOptions: HTMLReactParserOptions = {
  replace: replaceHead,
};
