import React, { FC } from 'react';
import { Box, Portal, Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import useTranslate from 'utils/i18n/useTranslate';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import {
  closeEmailConfirmModal,
  openLoginModal,
} from 'store/reducers/uiReducer';
import { ModalWithCloseOnBack } from 'components/ui';
import { confirmEmailModalStyles } from './confirm-email-modal.styles';

export const ConfirmEmailModal: FC = () => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();

  const showModal = useAppSelector(
    ({ ui: { isShowEmailConfirmModal } }) => isShowEmailConfirmModal,
  );

  const handleClose = () => {
    dispatch(closeEmailConfirmModal());
  };

  const onLogInClick = () => {
    handleClose();
    dispatch(openLoginModal());
  };

  return (
    <Portal>
      <ModalWithCloseOnBack
        open={showModal}
        onClose={handleClose}
        title={t('login.confirmEmail')}
        fixedWidth
        PaperProps={{
          id: 'confirm-email-modal',
          'area-labelledby': 'confirm-email-modal-button',
        }}
        keepMounted={false}
        titleComponent="h2"
        addHash={false}
      >
        <Typography sx={confirmEmailModalStyles.title}>
          {t('login.confirmEmailDescription')}
        </Typography>
        <Box textAlign="center">
          <Button fullWidth type="submit" onClick={onLogInClick}>
            {t('login.title')}
          </Button>
        </Box>
      </ModalWithCloseOnBack>
    </Portal>
  );
};
