import { validateCVFile } from 'validation/*';
import React, { useCallback, useState } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Box, Divider, Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import classnames from 'classnames';
import flashMan from 'public/images/icons/single-man-actions-flash.svg';
import upload from 'public/images/icons/upload-bottom.svg';
import { CandidatesRoutes } from 'constants/routes';
import { eventNames, logAnalyticsEvent } from 'utils/analytics/event';
import useTranslate from 'utils/i18n/useTranslate';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { uploadCV } from 'store/actions/cvActions';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { closeBuildCVModal } from 'store/reducers/uiReducer';
import { ModalWithCloseOnBack } from 'components/ui';
import { IBuildCvModal } from './build-cv-modal.interface';
import { useBuildCvModalStyles } from './build-cv-modal.styles';

const PointItem: React.FC<IBuildCvModal> = ({
  icon,
  text,
  imgAlt,
  imgTitle,
}) => (
  <Box width="100%" display="flex" mb={3}>
    <Box minWidth={54} mr={2}>
      <Image src={icon} alt={imgAlt} title={imgTitle} width={48} height={48} />
    </Box>
    <Box mt={1}>{text}</Box>
  </Box>
);

export const BuildCvModal = () => {
  const router = useRouter();
  const { t } = useTranslate();
  const classes = useBuildCvModalStyles();

  const showModal = useAppSelector(({ ui }) => ui.showBuildCVModal);
  const {
    partialComplete,
    fullComplete,
    data: { documents },
  } = useAppSelector(({ cv }) => cv);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const handleClose = useCallback(() => {
    dispatch(closeBuildCVModal());
  }, [dispatch]);

  const handleFileUpload = async ({ target }) => {
    const file = target?.files?.[0];
    const docType =
      file.name.substring(file.name.lastIndexOf('.'), file.name.length) ||
      file.name;

    if (file) {
      const { isValid, error } = validateCVFile(file, docType);
      if (isValid) {
        logAnalyticsEvent(eventNames.createCV, { category: 'CV_upload' });

        setLoading(true);
        await dispatch(
          uploadCV(target.files[0], () => {
            setLoading(false);
            router.push({
              pathname: CandidatesRoutes.resume,
              query: {
                step: 6,
                redirectUrl: router.asPath.split('#')[0],
              },
            });
            handleClose();
          }),
        );
      } else {
        dispatch(openFeedback({ type: 'error', message: error }));
      }
    }
  };
  const shouldBeFinishStep = !fullComplete && documents.length;
  const handleAddInformationManually = () => {
    logAnalyticsEvent(eventNames.createCV, { category: 'add_info_manually' });

    router.push({
      pathname: CandidatesRoutes.resume,
      query: {
        redirectUrl: router.asPath.split('#')[0],
      },
    });
    handleClose();
  };

  const handleCompleteProfile = async () => {
    await router.replace(router.asPath.split('#')[0], undefined, {
      shallow: true,
    });
    router.push(
      {
        pathname: CandidatesRoutes.resume,
        query: {
          step: 6,
          redirectUrl: router.asPath.split('#')[0],
        },
      },
      undefined,
      { shallow: true },
    );
    handleClose();
  };

  const modalTitle = !shouldBeFinishStep ? t('buildCV.title') : null;

  return (
    <ModalWithCloseOnBack
      open={showModal}
      onClose={handleClose}
      title={modalTitle}
      PaperProps={{
        id: 'build-CV',
        'area-labelledby': 'build-CV-button',
      }}
    >
      <>
        {shouldBeFinishStep ? (
          <PointItem
            icon={flashMan}
            text={
              <Typography variant="h4" className={classes.boldText}>
                {t('buildCV.completeCVTitle')}
              </Typography>
            }
            imgAlt={t('img.completeCVAlt')}
            imgTitle={t('img.completeCVTitle')}
          />
        ) : (
          <>
            <PointItem
              icon={flashMan}
              text={
                <Typography variant="h4" className={classes.boldText}>
                  {t('buildCV.toGetBetterMatches')}
                  <Typography variant="h4" component="span">
                    {t('buildCV.youNeed')}
                  </Typography>
                </Typography>
              }
              imgAlt={t('img.completeCVAlt')}
              imgTitle={t('img.completeCVTitle')}
            />
            <PointItem
              icon={upload}
              text={
                <Typography variant="h4">
                  {t('buildCV.youCanImportCV')}
                  <Typography
                    variant="h4"
                    component="span"
                    className={classes.boldText}
                  >
                    {t('buildCV.weWillPrefill')}
                  </Typography>
                </Typography>
              }
              imgAlt={t('img.importCVAlt')}
              imgTitle={t('img.importCVTitle')}
            />
          </>
        )}
        <Box marginLeft={-3} marginRight={-3} marginBottom={3}>
          <Divider />
        </Box>
        <Box className={classes.buttonsContainer}>
          {!partialComplete && !documents.length && (
            <>
              <input
                accept=".doc, .docx, .rtf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf"
                className={classes.fileInput}
                id="import-CV"
                type="file"
                onChange={handleFileUpload}
              />
              <label htmlFor="import-CV">
                <Button
                  className={classnames(classes.importButton, classes.button)}
                  component="span"
                  loading={loading}
                  disabled={loading}
                >
                  {t('buildCV.importCV')}
                </Button>
              </label>
            </>
          )}
          {shouldBeFinishStep ? (
            <Button
              className={classes.button}
              loading={loading}
              disabled={loading}
              onClick={handleCompleteProfile}
            >
              {t('buildCV.completeProfile')}
            </Button>
          ) : (
            <Button
              color="paleGrey"
              className={classes.button}
              loading={loading}
              disabled={loading}
              onClick={handleAddInformationManually}
            >
              {t('buildCV.addInfoManually')}
            </Button>
          )}
        </Box>
      </>
    </ModalWithCloseOnBack>
  );
};
