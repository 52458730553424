import { ISWRFiltersValuesDto } from 'types/*';
import { IFiltersValuesDto } from '@nploy/ui-infrastructure';
import { initialFiltersValuesData } from 'constants/user-filters';
import { getFromLocalStorage, localStorageKeys } from 'utils/localStorage';

export const createAuthedUserFilters = (
  data: ISWRFiltersValuesDto | IFiltersValuesDto,
) => ({
  country_id: data.country_id,
  salary_min: data.salary_min,
  salary_max: data.salary_max,
  cities: data.cities,
  keywords: data.keywords,
  experience_years_min: data.experience_years_min,
  experience_years_max: data.experience_years_max,
  // Change naming, leave jobCategories if it's a mutation
  jobCategories:
    (data as ISWRFiltersValuesDto)?.jobCategories ||
    (data as IFiltersValuesDto)?.job_occupations?.map((item) => item.id) ||
    [],
  // Set default value if none is selected (id if any, item if does not have id property - is id itself)
  employment_levels: data?.employment_levels
    ? data.employment_levels.map((item) => item?.id || item)
    : initialFiltersValuesData.employment_levels,
  employment_types: data?.employment_types
    ? data.employment_types.map((item) => item?.id || item)
    : initialFiltersValuesData.employment_types,
  work_models: data?.work_models || [],
});

export const createUnAuthedUserFilters = () => {
  const jobCategories = getFromLocalStorage(localStorageKeys.jobCategories);
  const countryId = getFromLocalStorage(localStorageKeys.country_id);
  const salaryMin = getFromLocalStorage(localStorageKeys.salary_min);
  const salaryMax = getFromLocalStorage(localStorageKeys.salary_max);
  const cities = getFromLocalStorage(localStorageKeys.cities);
  const keywords = getFromLocalStorage(localStorageKeys.keywords);
  const employmentLevels = getFromLocalStorage(
    localStorageKeys.employment_levels,
  );
  const employmentTypes = getFromLocalStorage(
    localStorageKeys.employment_types,
  );
  const experienceYearsMin = getFromLocalStorage(
    localStorageKeys.experience_years_min,
  );
  const experienceYearsMax = getFromLocalStorage(
    localStorageKeys.experience_years_max,
  );
  const workModels = getFromLocalStorage(localStorageKeys.work_models);

  return {
    jobCategories: jobCategories || [],
    country_id: countryId || '',
    salary_min: salaryMin || 0,
    salary_max: salaryMax || 0,
    cities: cities || [],
    keywords: keywords || [],
    employment_levels:
      employmentLevels || initialFiltersValuesData.employment_levels,
    employment_types:
      employmentTypes || initialFiltersValuesData.employment_types,
    experience_years_min:
      experienceYearsMin || initialFiltersValuesData.experience_years_min,
    experience_years_max:
      experienceYearsMax || initialFiltersValuesData.experience_years_max,
    work_models: workModels || initialFiltersValuesData.work_models,
  };
};
