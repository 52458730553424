import { useEffect, useState } from 'react';
import { AppBar, Box, Container, Toolbar } from '@mui/material';
import { useCV } from 'utils/swr/hooks/useCV';
import { useUser } from 'utils/swr/hooks/useUser';
import { Drawer } from '../drawer';
import { LinkedLogo } from '../linked-logo';
import { NavLinks } from '../nav-links';
import { RightPanel } from '../right-panel/right-panel';
import { headerWrapperStyles } from './header-wrapper.styles';

export const HeaderWrapper = () => {
  const [isClient, setIsClient] = useState(false);
  const {
    loading,
    user: { isAuthed, photo_url: photoUrl },
  } = useUser();
  useCV();

  const [showDrawer, setShowDrawer] = useState(false);

  const handleOpenDrawer = () => setShowDrawer(true);
  const handleCloseDrawer = () => setShowDrawer(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <div>
      <AppBar sx={headerWrapperStyles.appBar}>
        <Container>
          <Toolbar sx={headerWrapperStyles.toolbar}>
            {/* Logo */}
            <LinkedLogo />

            <Box sx={headerWrapperStyles.panel}>
              {/* Links */}
              <Box sx={headerWrapperStyles.links}>
                <NavLinks />
              </Box>

              {/* Right panel */}
              {isClient && (
                <RightPanel
                  isAuthed={isAuthed}
                  image={photoUrl}
                  openDrawer={handleOpenDrawer}
                  loading={loading}
                />
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        showDrawer={showDrawer}
        closeDrawer={handleCloseDrawer}
        openDrawer={handleOpenDrawer}
        isAuthed={isAuthed}
      />
    </div>
  );
};
