import { ForgotPasswordSchema } from 'validation/*';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button, TextField } from '@nploy/ui/web';
import { Formik, Form, Field } from 'formik';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { useAuth } from 'utils/swr/hooks/useAuth';
import {
  closeForgotPasswordModal,
  openCreateAccountModal,
} from 'store/reducers/uiReducer';
import { ModalWithCloseOnBack } from 'components/ui/';
import CustomLink from '../ui/CustomLink';

const ForgotPasswordModal = () => {
  const { t } = useTranslate();
  const classes = useStyles();
  const showModal = useSelector(({ ui }) => ui.showForgotPasswordModal);

  const { forgotPassword } = useAuth();

  const dispatch = useDispatch();

  const handleSubmit = async ({ email }, { resetForm }) => {
    await forgotPassword(email, () => dispatch(closeForgotPasswordModal()));
    resetForm();
  };

  const handleCreate = () => {
    dispatch(closeForgotPasswordModal());
    dispatch(openCreateAccountModal());
  };

  const handleClose = () => {
    dispatch(closeForgotPasswordModal());
  };

  return (
    <ModalWithCloseOnBack
      fixedWidth
      open={showModal}
      onClose={handleClose}
      title={t('forgotPassword.title')}
      classes={{ paper: classes.modal }}
    >
      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema(t)}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Box
              pr={[0, 1]}
              pl={[0, 1]}
              mb={2}
              pt={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Box>
                <Typography variant="body2">
                  {t('forgotPassword.sendAnEmail')}
                </Typography>
              </Box>

              <Box mb={1} width={1}>
                {/* Email */}
                <Field name="email">
                  {(props) => (
                    <TextField
                      type="email"
                      label={t('forgotPassword.email')}
                      fullWidth
                      {...props}
                    />
                  )}
                </Field>
              </Box>
              {/* Submit button */}
              <Box className={classes.buttonContainer}>
                <Button type="submit" className={classes.button}>
                  {t('forgotPassword.resetPassword')}
                </Button>
              </Box>
            </Box>
            {/* Don’t have an account */}
            <Box textAlign="center">
              <Typography variant="body2" component="span">
                {t('forgotPassword.dontHaveAnAccount')}{' '}
              </Typography>
              <CustomLink onClick={handleCreate}>
                {t('forgotPassword.create')}
              </CustomLink>
            </Box>
          </Form>
        )}
      </Formik>
    </ModalWithCloseOnBack>
  );
};

const useStyles = makeStyles(() => ({
  modal: {
    width: 440,
    maxHeight: 551,
    height: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
  button: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'unset',
    },
  },
}));

export default ForgotPasswordModal;
