import theme from 'theme/theme';

export const drawerStyles = {
  drawerRoot: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    height: '100%',
  },
  head: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    padding: theme.spacing(3),
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  buttons: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
    },
  },
};
