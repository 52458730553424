import React, { FC } from 'react';

export const ArrowDown: FC = () => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.95209L1.32873 0.457275L5.16436 3.86673L9 0.457275L10.3287 1.95209L5.16436 6.54264L0 1.95209Z"
      fill="#565656"
    />
  </svg>
);
