import { operatingSystemRules } from 'constants/operatingSystemRulesRegexp';
import { userAgentRules } from 'constants/userAgentRulesRegexp';

const REQUIRED_VERSION_PARTS = 3;

/**
 * Matches the user agent string with the list of user agent rules and returns the first match.
 *
 * @param {string} ua - The user agent string to match.
 * @return {[string, RegExpExecArray]} - An array containing the matched browser name and the match result.
 *                                       Returns null if no match is found.
 */
function matchUserAgent(ua: string): [string, RegExpExecArray] {
  return (
    ua !== '' &&
    userAgentRules.reduce(
      (
        matched: [string, RegExpExecArray] | null,
        [browser, regex]: [string, RegExp],
      ) => {
        if (matched) {
          return matched;
        }

        const uaMatch = regex.exec(ua);
        return !!uaMatch && [browser, uaMatch];
      },
      null,
    )
  );
}

export function detectOS(ua: string) {
  // eslint-disable-next-line no-plusplus
  for (let ii = 0, count = operatingSystemRules.length; ii < count; ii++) {
    const [os, regex] = operatingSystemRules[ii];
    const match = (regex as RegExp).exec(ua);
    if (match) {
      return os;
    }
  }

  return null;
}

function createVersionParts(count: number) {
  const output = [];
  // eslint-disable-next-line no-plusplus
  for (let ii = 0; ii < count; ii++) {
    output.push('0');
  }

  return output;
}

export function parseUserAgent(ua: string) {
  const matchedRule = matchUserAgent(ua);
  if (!matchedRule) {
    return null;
  }

  const [name, match] = matchedRule;

  let versionParts =
    match[1] && match[1].split('.').join('_').split('_').slice(0, 3);
  if (versionParts) {
    if (versionParts.length < REQUIRED_VERSION_PARTS) {
      versionParts = [
        ...versionParts,
        ...createVersionParts(REQUIRED_VERSION_PARTS - versionParts.length),
      ];
    }
  } else {
    versionParts = [];
  }
  const version = versionParts.join('.');
  const os = detectOS(ua);
  return { name, os, version };
}

export function detect() {
  if (typeof navigator !== 'undefined') {
    return parseUserAgent(navigator.userAgent);
  }
}
