import React from 'react';
import useTranslate from 'utils/i18n/useTranslate';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { closeLoginModal } from 'store/reducers/uiReducer';
import { ModalWithCloseOnBack } from 'components/ui';
import { LoginForm } from '../login-form';

export const LoginModal: React.FC = () => {
  const { t } = useTranslate();
  const { showLoginModal } = useAppSelector(({ ui }) => ui);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeLoginModal());
  };

  return (
    <ModalWithCloseOnBack
      open={showLoginModal}
      onClose={handleClose}
      title={t('login.title')}
      fixedWidth
      PaperProps={{
        id: 'login-modal',
        'area-labelledby': 'open-login-button',
      }}
      keepMounted={false}
      titleComponent="h2"
      addHash={false}
    >
      <LoginForm />
    </ModalWithCloseOnBack>
  );
};
