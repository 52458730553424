import { ExperienceClassifier } from '@nploy/ui/domain';

export const workExperienceYearsToValues = (
  minYears: number | null,
  maxYears: number | null,
): ExperienceClassifier => {
  if (minYears === 0 && maxYears === 1) {
    return ExperienceClassifier.entryLevel;
  }
  if (minYears === 1 && maxYears === 3) {
    return ExperienceClassifier.junior;
  }
  if (minYears === 3 && maxYears === 5) {
    return ExperienceClassifier.middle;
  }
  if (minYears === 5 && maxYears === 8) {
    return ExperienceClassifier.senior;
  }
  if (minYears >= 8) {
    return ExperienceClassifier.leadership;
  }
};

export const experienceValuesToYears = (experience: string): number[] => {
  switch (experience) {
    case ExperienceClassifier.junior:
      return [1, 3];
    case ExperienceClassifier.middle:
      return [3, 5];
    case ExperienceClassifier.senior:
      return [5, 8];
    case ExperienceClassifier.leadership:
      return [8, 15];
    default:
      return [0, 1];
  }
};
