import chromeImage from 'public/images/browsers/chrome.svg';
import firefoxImage from 'public/images/browsers/firefox.svg';
import IEImage from 'public/images/browsers/internet-explorer.svg';
import edgeImage from 'public/images/browsers/microsoft-edge.svg';
import operaImage from 'public/images/browsers/opera.svg';
import safariImage from 'public/images/browsers/safari.svg';
import samsungInternetImage from 'public/images/browsers/samsung-internet.svg';

const browser = {
  opera: {
    name: 'Opera',
    img: operaImage,
    agentName: 'Opera',
  },
  chrome: {
    name: 'Chrome',
    img: chromeImage,
    agentName: 'Chrome',
  },
  safari: {
    name: 'Safari',
    img: safariImage,
    agentName: 'Safari',
  },
  firefox: {
    name: 'Mozilla Firefox',
    img: firefoxImage,
    agentName: 'Firefox',
  },
  ie: {
    name: 'Microsoft Internet Explorer',
    img: IEImage,
    agentName: 'Trident',
  },
  edge: {
    name: 'Microsoft Edge',
    img: edgeImage,
    agentName: 'Edge',
  },
  samsung: {
    name: 'Samsung Internet',
    img: samsungInternetImage,
    agentName: 'SamsungBrowser',
  },
  unknown: {
    name: '',
    img: '',
  },
};

export const detectIfMobileBrowser = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const detectBrowserName = (browserData) => {
  switch (browserData && browserData?.name) {
    case 'crios':
    case 'chrome':
      return browser.chrome;
    case 'ios':
      return browser.safari;
    case 'ios-webview':
    case 'opera':
      return browser.opera;
    case 'fxios':
    case 'firefox':
      return browser.firefox;
    case 'edge':
    case 'edge-ios':
    case 'edge-chromium':
      return browser.edge;
    case 'ie':
      return browser.ie;
    case 'opera-mini':
      return browser.opera;
    default:
      return browser.unknown;
  }
};
