import Image from 'next/image';
import { styled } from '@mui/styles';
import theme from 'theme/theme';

export const LinkedLogoImage = styled(Image)({
  height: 40,
  objectFit: 'scale-down',
  marginRight: theme.spacing(1.8),
  cursor: 'pointer',
  width: '100% !important',
  maxWidth: 125,
  [theme.breakpoints.down(1200)]: {
    height: 70,
  },
  [theme.breakpoints.up('lg')]: {
    marginRight: theme.spacing(5),
  },
});
