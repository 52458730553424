import React, { FC } from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslate from 'utils/i18n/useTranslate';
import { IMoreMenuItem } from './more-menu-item.interface';
import { moreMenuItemStyles } from './more-menu-item.styles';

export const MoreMenuItem: FC<IMoreMenuItem> = ({ icon, text, onClick }) => {
  const { t } = useTranslate();

  return (
    <MenuItem sx={moreMenuItemStyles.item} onClick={onClick}>
      <ListItemIcon sx={moreMenuItemStyles.icon}>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          sx: moreMenuItemStyles.itemText,
        }}
      >
        {t(`moreMenu.${text}`)}
      </ListItemText>
    </MenuItem>
  );
};
