import useSWR from 'swr';
import { fetchData } from '../helpers/fetchData';
import { shouldFetchIfHasToken } from '../helpers/shouldFetch';

export const useUnreadMessages = () => {
  const { data, error, mutate } = useSWR(
    shouldFetchIfHasToken() ? 'messenger/count-unread-message' : null,
    fetchData,
    {
      refreshInterval: 10000,
    },
  );

  return {
    unreadMessages: data?.unread_messages,
    refreshUnreadMessages: mutate,
    error,
  };
};
