import palette from 'theme/palette';
import theme from 'theme/theme';

export const languageMenuItemStyles = {
  item: {
    px: 1,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    '&:hover': {
      backgroundColor: palette.paleGrey.main,
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1.25),
      paddingBottom: theme.spacing(1.25),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25),
    },
  },
  image: {
    width: 'calc(100% + 2px)',
    objectFit: 'contain',
  },
  itemText: {
    fontWeight: 400,
    margin: 0,
    fontSize: '1.25rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
  },
  isSelected: {
    textDecoration: 'underline',
    [theme.breakpoints.up('md')]: {
      textDecoration: 'none',
    },
  },
  icon: {
    width: '54px',
    height: '32.4px',
    borderRadius: 1,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
