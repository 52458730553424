import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  Box,
  Divider,
  IconButton,
  SwipeableDrawer as MuiDrawer,
} from '@mui/material';
import { Button } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import { eventNames, logAnalyticsEvent } from 'utils/analytics/event';
import useTranslate from 'utils/i18n/useTranslate';
import {
  openBuildCVModal,
  openCreateAccountModal,
  openLoginModal,
} from 'store/reducers/uiReducer';
import { LanguageMenu } from '../language-menu';
import { LinkedLogo } from '../linked-logo';
import { MoreMenu } from '../more-menu';
import { NavLinks } from '../nav-links';
import { IDrawer } from './drawer.interface';
import { drawerStyles } from './drawer.styles';

export const Drawer: FC<IDrawer> = ({
  showDrawer,
  closeDrawer,
  openDrawer,
  isAuthed,
}) => {
  const router = useRouter();
  const { t } = useTranslate();
  const dispatch = useDispatch();

  const { fullComplete: isCVCompleted, partialComplete } = useSelector(
    ({ cv }) => cv,
  );

  const handleOpenCreateAccountModal = () => {
    closeDrawer();
    dispatch(openCreateAccountModal());
  };

  const handleOpenLoginModal = () => {
    closeDrawer();
    dispatch(openLoginModal());
  };

  const handleOpenBuildCVModal = () => {
    closeDrawer();

    logAnalyticsEvent(eventNames.buildProfile);

    dispatch(openBuildCVModal());
  };

  return (
    <MuiDrawer
      anchor="top"
      open={showDrawer}
      onClose={closeDrawer}
      onOpen={openDrawer}
      ModalProps={{
        sx: drawerStyles.drawerRoot,
      }}
      PaperProps={{
        sx: drawerStyles.drawer,
      }}
      transitionDuration={600}
      id="navigation-menu"
    >
      <Box sx={drawerStyles.head}>
        <LinkedLogo />

        <IconButton
          onClick={closeDrawer}
          style={{
            width: 50,
            height: 50,
          }}
        >
          <FontAwesomeIcon
            icon={faTimes as IconProp}
            color={palette.black.main}
            size="sm"
          />
        </IconButton>
      </Box>
      <Box sx={drawerStyles.content}>
        <Box sx={drawerStyles.buttons}>
          {isAuthed ? (
            <>
              {/* Authed */}
              {/* Build CV */}
              {router.pathname !== '/resume' && !isCVCompleted && (
                <Button
                  color="secondary"
                  fullWidth
                  onClick={handleOpenBuildCVModal}
                >
                  {partialComplete
                    ? t('navigation.profileIs90')
                    : t('navigation.buildCV')}
                </Button>
              )}
            </>
          ) : (
            <>
              {/* Not authed */}
              {/* Create account */}
              <Button fullWidth mb={1} onClick={handleOpenCreateAccountModal}>
                {t('navigation.createAccount')}
              </Button>

              {/* Login */}
              <Button
                color="paleGrey"
                fullWidth
                mb={1}
                onClick={handleOpenLoginModal}
              >
                {t('navigation.login')}
              </Button>
            </>
          )}
        </Box>

        <NavLinks inDrawer />

        <Box mb={2}>
          <Divider />
        </Box>

        {isAuthed && (
          <>
            <MoreMenu onClose={closeDrawer} />

            <Box mb={2}>
              <Divider />
            </Box>
          </>
        )}

        <LanguageMenu onClose={closeDrawer} />
      </Box>
    </MuiDrawer>
  );
};
