import { swapObject } from '@nploy/shared';

export const employmentLevelsToSearchParams = {
  1: 'Full',
  2: 'Part',
} as const;

export const fromEmploymentLevelsSearchParamsToIds = swapObject(
  employmentLevelsToSearchParams,
);
