import { SxProps } from '@mui/material';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const dropdownNavLinksStyles = {
  navDropdown: (inDrawer: boolean): SxProps => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: inDrawer ? 'space-between' : 'center',
    width: inDrawer ? '100%' : 'auto',
    cursor: 'pointer',
    fontSize: inDrawer ? '1.3rem' : '1.0625rem',
    fontWeight: inDrawer ? 400 : 600,
    marginRight: inDrawer ? 0 : theme.spacing(1),
    marginY: inDrawer ? theme.spacing(1) : 0,
  }),
  link: {
    textDecoration: 'none',
    color: theme.palette.common.black,

    '&:hover': {
      color: palette.lighterGrey.main,
    },
  },
  closeMenu: {
    transform: 'scaleY(1)',
    transition: 'transform 0.3s',
  },
  arrow: (open: boolean): SxProps => ({
    transform: open ? 'scaleY(-1)' : 'scaleY(1)',
    transition: 'transform 0.3s',
  }),
};
