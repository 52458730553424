import { swapObject } from '@nploy/shared';

export const employmentTypesToSearchParams = {
  1: 'Regular',
  2: 'Project',
  4: 'Internship',
  6: 'Voluntary',
  7: 'Manager',
  5: 'Remote',
} as const;

export const fromJobEmploymentTypesSearchParamsToIds = swapObject(
  employmentTypesToSearchParams,
);
